<template>
  <div>
    <!--Progress-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Title Bar-->
    <title-bar v-if="checkRequestSection === 0" :title-value="'Check Requests'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-money-check-alt</v-icon>
        <v-toolbar-title class="pl-2">Check Requests</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="checkRequestSection !== 3" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon
            ><span
              v-if="
                checkRequestsStore.filters.f_status || checkRequestsStore.filters.f_status === 0
              "
              >Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="12"
                  v-if="
                    checkRequestsStore.filters.f_status || checkRequestsStore.filters.f_status === 0
                  "
                >
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="filterBar.d_date"
                        label="Date"
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(filterBar.d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.checkRequestStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="filterBar.f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="
        checkRequestsStore.checkRequests.data && checkRequestsStore.checkRequests.data.length > 0
      "
      :showTotal="true"
      :currentPage="checkRequestsStore.checkRequests.current_page"
      :lastPage="checkRequestsStore.checkRequests.last_page"
      :total="checkRequestsStore.checkRequests.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progress !== true">
      <!--List-->
      <list-to-detail
        :show="
          checkRequestsStore.checkRequests.data && checkRequestsStore.checkRequests.data.length > 0
            ? true
            : false
        "
        :list-data="checkRequestsStore.checkRequests.data"
        :title="'Check Requests'"
        :section="23"
        @open-detail="detail($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListToDetail from "@/components/ListToDetail";

export default {
  name: "CheckRequests",
  components: {
    TitleBar,
    Pagination,
    ListToDetail
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      checkRequestsStore: state => state.checkRequestsStore,
      valueListsStore: state => state.valueListsStore
    })
  },
  props: {
    /* All List = 1, Provider = 2, Client = 3 */
    checkRequestSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    date1() {
      this.filterBar.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      progress: true,
      filterMenu: false,
      filterBar: {
        d_date: "",
        f_status: "",
        page: 1
      },
      status: [
        {
          id: 1,
          value: "Pending"
        },
        {
          id: 0,
          value: "Canceled"
        },
        {
          id: 2,
          value: "Approved"
        }
      ],
      menu: false,
      date1: ""
    };
  },
  methods: {
    async initialize() {
      const filters = this.$store.getters["checkRequestsStore/getFilters"];

      this.filterBar.d_date = this.$_formatDate(filters.d_date);
      this.filterBar.f_status = filters.f_status;

      let provider;
      let data;
      /* Provider Section */
      if (this.checkRequestSection === 2) {
        // await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
        provider = this.$store.getters["providersStore/getProvider"];
        data = {
          fk_providerID: provider.id,
          fk_clientID: "",
          d_date: this.$_parseDate(this.filterBar.d_date),
          f_status: this.filterBar.f_status
        };
      } else if (this.checkRequestSection === 3) {
      /* Clients */
        const client = this.$store.getters["clientsStore/getClient"];

        data = {
          fk_providerID: "",
          fk_clientID: client.id,
          d_date: "",
          f_status: 1
        };
      } else {
        data = {
          d_date: this.$_parseDate(this.filterBar.d_date),
          f_status: this.filterBar.f_status
        };
      }

      data.page = filters.page;
      data.section = this.checkRequestSection;

      await this.$store.dispatch("checkRequestsStore/fetch", data);
      this.progress = false;
    },

    async filter(page) {
      this.filterMenu = false;
      this.progress = true;

      let data;
      /* Provider */
      if (this.checkRequestSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];

        data = {
          fk_providerID: provider.id,
          fk_clientID: "",
          d_date: this.date1,
          f_status: this.filterBar.f_status
        };
      } else if (this.checkRequestSection === 3) {
      /* Client */
        const client = this.$store.getters["clientsStore/getClient"];

        this.filterBar.fk_userID_Assigned = user.id;
        data = {
          fk_providerID: "",
          fk_clientID: client.id,
          d_date: "",
          f_status: 1
        };
      } else {
        data = {
          d_date: this.$_parseDate(this.filterBar.d_date),
          f_status: this.filterBar.f_status
        };
      }

      data.page = page;
      data.section = this.checkRequestSection;

      this.$store.dispatch("checkRequestsStore/storeFilters", data);
      await this.$store.dispatch("checkRequestsStore/fetch", data);
      this.progress = false;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.progress = true;

      this.filterBar.d_date = "";
      this.filterBar.f_status = "";

      let data;
      /* Provider */
      if (this.checkRequestSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];

        data = {
          fk_providerID: "",
          fk_clientID: "",
          f_status: ""
        };
      } else if (this.checkRequestSection === 3) {
      /* Dashboard */
        const user = this.$store.getters["usersStore/getAuthUser"];

        this.filterBar.fk_userID_Assigned = user.id;
        data = {
          fk_providerID: "",
          fk_clientID: "",
          d_date: "",
          f_status: 1
        };
      } else {
        data = {
          fk_providerID: "",
          fk_clientID: "",
          d_date: "",
          f_status: ""
        };
      }

      data.d_date = "";
      data.page = 1;
      data.section = this.checkRequestSection;

      this.$store.dispatch("checkRequestsStore/storeFilters", data);
      await this.$store.dispatch("checkRequestsStore/fetch", data);
      this.progress = false;
    },

    async openEntry(id) {
      await this.$store.dispatch("checkRequestsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
