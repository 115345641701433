<template>

<div>

  <check-requests :checkRequestSection="0" />

</div>

</template>

<script>
import CheckRequests from '@/components/CheckRequests';

export default {
  name: 'CheckRequestList',
  components: {
    CheckRequests,
  },
};
</script>

<style scoped>

</style>
